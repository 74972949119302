import React, {useState, useEffect, useMemo} from "react";
import TokenService from "../../service/TokenService";
import Loading from "../Loading/Loading";
import Error from "../Error/Error";
import ErrorUtils from "../../utils/ErrorUtils";
import {Redirect} from "react-router-dom";
import AppsService from "../../service/AppsService";

export default function Login() {
    const [loggedIn, setLoggedIn] = useState(null);
    const [error, setError] = useState(null);
    const [username, setUsername] = useState(null);
    const [fullName, setFullName] = useState(null);
    const [app, setApp] = useState(null);

    const appId = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.search)
        return urlParams.get("app");
    }, []);

    useEffect(() => {
        TokenService.validate()
            .then(response => {
                let isLoggedIn = !!response;
                setUsername(isLoggedIn ? response.user.username : null);
                setFullName(isLoggedIn ? response.user.firstName + " " + response.user.lastName : null);
                setLoggedIn(isLoggedIn);
            })
            .catch(error => {
                setLoggedIn(false);
                setError(ErrorUtils.getErrorMessage(error));
            });
    }, []);

    useEffect(() => {
        AppsService.getApp(appId)
            .then(app => {
                if(app.ssoCallback === null) {
                    setError("App with id '" + appId + "' is invalid for SSO login.");
                } else {
                    setApp(app);
                }
            })
            .catch(error => {
                if(error.response.status === 404) {
                    setError("App with id '" + appId + "' does not exist.");
                } else {
                    setError(ErrorUtils.getErrorMessage(error));
                }
            });
    }, [appId]);

    if(appId == null) {
        return <Redirect to="/" />;
    }

    const goBack = () => {
        window.history.back();
    }

    if(loggedIn === null || app === null)
        return (
            <div>
                {error ? (
                    <div>
                        <Error message={error}/>
                        <div className={"Main"}>
                            <button onClick={goBack} className="btn btn-md btn-secondary btn-border btn-block">
                                Go Back
                            </button>
                        </div>
                    </div>
                ) : (
                    <Loading/>
                )}
            </div>
        );

    if(!loggedIn)
        return <Redirect to={{pathname: "/login/", state: {return: "/sso/" + window.location.search}}} />;

    const submit = () => {
        TokenService.generateSso(appId)
            .then(ssoToken => {
                window.location.href = app.ssoCallback + "?token=" + encodeURIComponent(ssoToken);
            })
            .catch(error => {
                setLoggedIn(false);
                setError(ErrorUtils.getErrorMessage(error));
            });
    }

    return (
        <div className={"Sso"}>
            <Error message={error}/>
            <div className={"Main"}>
                <h1>Login</h1>
                <p>
                    Would you like to proceed logging in to application "{app.name}" as {fullName} ({username}).
                </p>
                <button onClick={submit} className="btn btn-md btn-primary btn-block">
                    Proceed
                </button>
                <button onClick={goBack} className="btn btn-md btn-secondary btn-border btn-block">
                    Go Back
                </button>
            </div>
        </div>
    );
}
