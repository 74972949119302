import React, {useState, useEffect} from "react";
import Loading from "../Loading/Loading";
import {Link, Redirect} from "react-router-dom";
import Error from "../Error/Error";
import ReCAPTCHA from "react-google-recaptcha";
import TokenService from "../../service/TokenService";
import ErrorUtils from "../../utils/ErrorUtils";
import RecaptchaService from "../../service/RecaptchaService";
import AccountService from "../../service/AccountService";

export default function Register() {
    const recaptchaRef = React.createRef();
    const [loggedIn, setLoggedIn] = useState(null);
    const [registered, setRegistered] = useState(false);
    const [recaptchaSiteKey, setRecaptchaSiteKey] = useState(null);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [error, setError] = useState(null);

    useEffect(() => {
        TokenService.validate()
            .then((response) => {
                setLoggedIn(!!response);
            })
            .catch(error => {
                setLoggedIn(false);
                setError(ErrorUtils.getErrorMessage(error));
            });
    }, []);

    useEffect(() => {
        RecaptchaService.getSiteKey()
            .then(setRecaptchaSiteKey)
            .catch(error => {
                setLoggedIn(false);
                setError(ErrorUtils.getErrorMessage(error));
            });
    }, []);

    const onSubmit = async (event) => {
        event.preventDefault();
        recaptchaRef.current.execute();
    };

    const onRecaptchaChange = (recaptchaResponse) => {
        if(password !== verifyPassword)
            return;

        AccountService.register(firstName, lastName, username, password, recaptchaResponse)
            .then(() => {
                setRegistered(true);
            })
            .catch(error => {
                setError(ErrorUtils.getErrorMessage(error));
            })
            .finally(() => {
                window.grecaptcha.reset();
            });
    };

    const isInvalid = () => {
        return password !== verifyPassword || password === "" || username === "" || firstName === "" || lastName === "";
    };

    const submitButtonTitle = () => {
        if(isInvalid()) {
            return "Please fill out all required fields";
        }
        return null;
    };

    if(loggedIn === null || recaptchaSiteKey === null)
        return <Loading/>;

    if(loggedIn)
        return <Redirect to={"/"} />;

    if(registered)
        return <Redirect to={"/login/"} />;

    return (
        <div className="Register Main">
            <Error message={error}/>
            <h1>Register</h1>
            <form onSubmit={onSubmit}>
                <input name="username" type="text" id="inputUsername" onChange={(event) => {setUsername(event.target.value)}}
                       className="form-control not-rounded-bottom" placeholder="Username" value={username}/>
                <input name="password" type="password" id="inputPassword" onChange={(event) => {setPassword(event.target.value)}}
                       className="form-control not-rounded-both" placeholder="Password" value={password}/>
                <input name="verifyPassword" type="password" id="inputVerifyPassword" onChange={(event) => {setVerifyPassword(event.target.value)}}
                       className="form-control not-rounded-top input-error-next" placeholder="Verify Password" value={verifyPassword}/>

                <div className="input-error">
                    {password === verifyPassword || password === "" ? "" : "Passwords don't match"}
                </div>

                <input name="firstName" type="text" id="inputFirstName" onChange={(event) => {setFirstName(event.target.value)}}
                       className="form-control not-rounded-bottom" placeholder="First Name" value={firstName}/>
                <input name="lastName" type="text" id="inputLastName" onChange={(event) => {setLastName(event.target.value)}}
                       className="form-control not-rounded-top" placeholder="Last Name" value={lastName}/>

                <ReCAPTCHA className="recaptcha" ref={recaptchaRef} size="invisible" sitekey={recaptchaSiteKey} onChange={onRecaptchaChange}/>

                <button className="btn btn-lg btn-primary btn-block" type="submit" title={submitButtonTitle()}
                        disabled={isInvalid()}>
                    Register
                </button>

                <div className="button-alt-action-text">
                    <Link to="/login/">Already a user? Login now</Link>
                </div>
            </form>
        </div>
    );
}