import React, {useState, useEffect} from "react";
import {
    Redirect,
    Link
} from "react-router-dom"

import Error from "../Error/Error";
import Success from "../Success/Success";
import Loading from "../Loading/Loading";
import TokenService from "../../service/TokenService";
import ErrorUtils from "../../utils/ErrorUtils";

export default function Manage(props) {
    const [loggedIn, setLoggedIn] = useState(null);
    const [error, setError] = useState(null);

    let success = null;
    if(props?.location?.state?.success)
        success = props?.location?.state?.success;

    useEffect(() => {
        TokenService.validate()
            .then((response) => {
                setLoggedIn(!!response);
            })
            .catch(error => {
                setLoggedIn(false);
                setError(ErrorUtils.getErrorMessage(error));
            });
    }, []);

    const logout = () => {
        TokenService.invalidate()
            .then(() => {
                setLoggedIn(false);
            })
            .catch((error) => {
                setError(ErrorUtils.getErrorMessage(error));
            });
    };

    if(loggedIn === null)
        return <Loading/>;

    if(!loggedIn)
        return <Redirect to={{pathname: "/login/", state: {return: "/" + window.location.search}}} />;

    return (
        <div className="Manage">
            <Error message={error} />
            <Success message={success} />
            <div className="Main">
                <h1>Manage</h1>
                <ul>
                    <li>
                        <Link to="/password/">Change Password</Link>
                    </li>
                </ul>
                <button onClick={logout} className={"btn btn-lg btn-primary btn-block"}>Logout</button>
            </div>
        </div>
    )
}
