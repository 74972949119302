import axios from "axios";
import TokenService from "../service/TokenService";

export default class AxiosAuthenticationManager {
    _accessTokenResolver;

    set accessTokenResolver(accessTokenResolver) {
        this._accessTokenResolver = accessTokenResolver;
    }

    setupInterceptors() {
        axios.interceptors.request.use(
            config => {
                if(!config.skipAuthRefresh) {
                    this._setupAuthorizationHeaders(config.headers);
                }
                config.withCredentials = true;
                config.headers["Content-Type"] = "application/json";
                return config;
            },
            error => {
                return Promise.reject(error)
            });

        axios.interceptors.response.use(
            response => {
                return response
            },
            error => {
                const originalRequest = error.config;
                if (error?.response?.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;

                    if(originalRequest.skipAuthRefresh) {
                        return Promise.reject(error);
                    }

                    return TokenService.generate()
                        .then(() => {
                            if(this._setupAuthorizationHeaders(axios.defaults.headers.common)) {
                                return axios(originalRequest);
                            }
                        })
                }

                // Don't expose error for token generate endpoint
                if(originalRequest.url !== TokenService.getGenerateUrl()) {
                    return Promise.reject(error);
                }
            }
        );
    }

    _setupAuthorizationHeaders(headers) {
        const accessToken = TokenService.getAccessToken();
        headers["Authorization"] = accessToken ? "Bearer " + accessToken : null;
        return !!accessToken;
    }
}