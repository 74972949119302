export default class ErrorUtils {

    static getErrorMessage(error) {
        let message = error?.response?.data?.message;
        if(message) {
            return message;
        }
        message = error?.message;
        if(message) {
            return message;
        }
        return "Unknown Error";
    }
}