import React, {useState, useEffect} from "react";
import Error from "../Error/Error";
import AccountService from "../../service/AccountService";
import ErrorUtils from "../../utils/ErrorUtils";
import {Redirect} from "react-router-dom";
import Loading from "../Loading/Loading";
import TokenService from "../../service/TokenService";

export default function ChangePassword() {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [loggedIn, setLoggedIn] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        TokenService.validate()
            .then((response) => {
                setLoggedIn(!!response);
            })
            .catch(error => {
                setLoggedIn(false);
                setError(ErrorUtils.getErrorMessage(error));
            });
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        if(newPassword !== verifyPassword)
            return;
        AccountService.changePassword(oldPassword, newPassword)
            .then(() => {
                setPasswordChanged(true);
            })
            .catch(error => {
                let errorMessage = ErrorUtils.getErrorMessage(error);
                if(errorMessage.includes("Invalid username")) {
                    errorMessage = "Invalid old password!"
                }
                setError(errorMessage);
            });
    };

    if(passwordChanged)
        return <Redirect to={{
            pathname: "/",
            state: { success: "Successfully changed password" }
        }}/>;

    if(loggedIn === null)
        return <Loading/>;

    if(!loggedIn)
        return <Redirect to={{pathname: "/login/", state: {return: "/" + window.location.search}}} />;

    return (
        <div className="ChangePassword">
            <Error message={error}/>
            <div className="Main">
                <h2>Change Password</h2>
                <form onSubmit={handleSubmit}>
                    <input type="password" value={oldPassword} onChange={(event) => {setOldPassword(event.target.value)}}
                           className="form-control old-password" placeholder="Old Password"/>

                    <input type="password" value={newPassword} onChange={(event) => {setNewPassword(event.target.value)}}
                           className="form-control not-rounded-bottom" placeholder="New Password"/>
                    <input type="password" value={verifyPassword} onChange={(event) => {setVerifyPassword(event.target.value)}}
                           className="form-control not-rounded-top verify-password input-error-next" placeholder="Verify Password"/>
                    <div className="input-error">
                        {newPassword === verifyPassword || newPassword === "" ? "" : (
                            "Passwords don't match"
                        )}
                    </div>
                    <button className="btn btn-lg btn-primary btn-block" type="submit"
                            disabled={newPassword!==verifyPassword || newPassword===""}>
                        Update
                    </button>
                </form>
            </div>
        </div>
    )
}