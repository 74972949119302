import ApiService from "./ApiService";
import axios from "axios";

export default class RecaptchaService extends ApiService {

    static async getSiteKey() {
        let response = await axios.get(this.getApiAddress() + "/v1/recaptcha/site-key", {
            skipAuthRefresh: true
        });
        return response?.data?.siteKey;
    }
}