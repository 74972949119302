import Cookies from "universal-cookie";

export default class ApiService {
    static cookies = new Cookies();

    static getApiAddress(): string {
        if(this.apiAddress) {
            return this.apiAddress;
        }

        if(process.env.REACT_APP_API_ADDRESS) {
            return process.env.REACT_APP_API_ADDRESS;
        }

        this.apiAddress = this.getApiProtocolAndHostname();
        return this.apiAddress;
    }

    static getApiProtocolAndHostname(): string {
        let url = window.location.href;
        let arr = url.split("/");
        return arr[0] + "//api." + arr[2];
    }

    static async retry(action) {
        while(true) {
            try {
                return await action();
            } catch (error) {
                alert(error);
                await this.sleep(5000);
            }
        }
    }

    static sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}