import React, {useState, useEffect, useMemo} from "react";
import TokenService from "../../service/TokenService";
import Loading from "../Loading/Loading";
import {Link, Redirect} from "react-router-dom";
import Error from "../Error/Error";
import ErrorUtils from "../../utils/ErrorUtils";

export default function Login(props) {
    const [loggedIn, setLoggedIn] = useState(null);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [error, setError] = useState(null);

    const returnPath = useMemo(() => {
        if(props?.location?.state?.return)
            return props?.location?.state?.return;
        return "/";
    }, [props]);

    useEffect(() => {
        TokenService.validate()
            .then(response => {
                setLoggedIn(!!response);
            })
            .catch(error => {
                setLoggedIn(false);
                setError(ErrorUtils.getErrorMessage(error));
            });
    }, []);

    const handleSubmit = (event) => {
        TokenService.generate(username, password, rememberMe)
            .then(response => {
                setLoggedIn(!!response);
            })
            .catch(error => {
                setLoggedIn(false);
                setError(ErrorUtils.getErrorMessage(error));
            });
        event.preventDefault();
    };

    if(loggedIn === null)
        return <Loading/>;

    if(loggedIn)
        return <Redirect to={returnPath} />;

    return (
        <div className={"Login"}>
            <Error message={error}/>
            <div className={"Main"}>
                <form onSubmit={handleSubmit}>
                    <h1>Login</h1>
                    <input type="text" value={username} onChange={(event) => {setUsername(event.target.value)}}
                           className="form-control not-rounded-bottom" placeholder="Username"/>
                    <input type="password" value={password} onChange={(event) => {setPassword(event.target.value)}}
                           className="form-control not-rounded-top" placeholder="Password"/>

                    <label className={"rememberMe"}>
                        <input name="rememberMe" type="checkbox" checked={rememberMe}
                               onChange={(e) => setRememberMe(e.target.checked)} />
                        <span>Keep me logged in</span>
                    </label>

                    <button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
                    <div className="button-alt-action-text">
                        <Link to="/register/">Not a user? Register an account</Link>
                    </div>
                </form>
            </div>
        </div>
    );
}
