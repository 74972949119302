import ApiService from "./ApiService";
import axios from "axios";

export default class AccountService extends ApiService {

    static async changePassword(oldPassword, newPassword) {
        await axios.put(this.getApiAddress() + "/v1/account/password", {
            oldPassword: oldPassword,
            newPassword: newPassword
        });
    }

    static async register(firstName, lastName, username, password, recaptchaResponse) {
        console.log(recaptchaResponse);
        await axios.post(this.getApiAddress() + "/v1/account", {
            firstName: firstName,
            lastName: lastName,
            username: username,
            password: password,
            recaptchaResponse: recaptchaResponse
        })
    }
}