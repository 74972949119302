import ApiService from "./ApiService";
import axios from "axios";

export default class AppsService extends ApiService {
    static async getApp(appId) {
        let response = await axios.get(this.getApiAddress() + "/v1/apps/" + appId, {
            skipAuthRefresh: true
        });
        return response?.data;
    }
}