import React from "react";

export default function NotFound() {
    return (
        <div className="Register Main">
            <h1>Not Found</h1>
            <p>
                The requested page could not be found!
            </p>
        </div>
    );
}
