import React from "react";

export default function Error(props) {
    if(!props.message)
        return (<div/>);

    return (
        <div className="Alert Error">
            <div className="alert alert-danger" role="alert">
                <strong>Error</strong> {props.message}
            </div>
        </div>
    );
}