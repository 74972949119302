import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route, Redirect,
} from "react-router-dom"

import Login from "../Login/Login";
import Manage from "../Manage/Manage";
import ChangePassword from "../ChangePassword/ChangePassword";
import Register from "../Register/Register";
import Sso from "../Sso/Sso";
import NotFound from "../NotFound/NotFound";

import './App.css';
import AxiosAuthenticationManager from "../../axios-authentication-manager/AxiosAuthenticationManager";
import TokenService from "../../service/TokenService";

export default function App() {
    const axiosAuthenticationManager = new AxiosAuthenticationManager();
    axiosAuthenticationManager.accessTokenResolver = TokenService.getAccessToken;
    axiosAuthenticationManager.setupInterceptors();

    return (
        <Router>
            <div>
                <Switch>
                    <Route exact path="/" component={Manage} />
                    <Route exact path="/password/" component={ChangePassword} />
                    <Route exact path="/login/" component={Login} />
                    <Route exact path="/register/" component={Register} />
                    <Route exact path="/sso/" component={Sso} />
                    <Route exact path="/manage/">
                        <Redirect to="/" />;
                    </Route>
                    <Route component={NotFound} />
                </Switch>
            </div>
        </Router>
    );
}
