import ApiService from "./ApiService";
import axios from "axios";
import Cookies from "universal-cookie";

export default class TokenService extends ApiService {
    static cookies = new Cookies();

    static getAccessToken() {
        return this.cookies.get("accessToken", { path: "/" });
    }

    static setAccessToken(accessToken) {
        this.cookies.set("accessToken", accessToken, {
            path: "/",
            sameSite: true,
            secure: process.env.REACT_SECURE
        });
    }

    static removeAccessToken() {
        this.cookies.remove("accessToken", { path: "/" });
    }

    static async generate(username, password, rememberMe) {
        let data = {};
        if(username && password) {
            data = {
                appId: "login",
                username: username,
                password: password,
                useCookie: true,
                rememberMe: !!rememberMe
            }
        }

        let response = await axios.post(this.getGenerateUrl(), data, {
            skipAuthRefresh: true
        });
        let accessToken =  response?.data?.accessToken;

        if(accessToken) {
            this.setAccessToken(accessToken);
        }
        return response;
    }

    static async generateSso(appId) {
        let data = {
            appId: appId
        }

        let response = await axios.post(this.getApiAddress() + "/v1/sso", data);
        return response?.data?.ssoToken;
    }

    static getGenerateUrl() {
        return this.getApiAddress() + "/v1/tokens";
    }

    static async validate() {
        try {
            let response = await axios.get(this.getApiAddress() + "/v1/tokens");
            return response?.data;
        } catch (e) {
            if(e?.response?.status === 401) {
                return null;
            } else {
                throw e;
            }
        }
    }

    static async invalidate() {
        let response = await axios.delete(this.getApiAddress() + "/v1/tokens");
        if(response.status === 200) {
            this.removeAccessToken();
        }
    }
}